import React from "react";
import blog1Data from "data/elf/blogs.json";
import blog3Data from "data/elf/insider.json";
import Navbar from "components/Navbar/navbar";
import BlogGrid from "components/Blog-grid/blog-grid.jsx";
import BlogGridElf from "components/Elf/blogGridElf/blogGridElf.jsx";
import BlogStanderd from "components/Blog-standerd/blog-standerd";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";

const Insider = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Insider"
        paragraph="Current news and events of our creative team."
      />
      
      {/* <BlogGrid blogs={blog3Data} /> */}
      <h1 className="d-none">Current news and events of our creative team.</h1>
      <BlogGridElf blogs={blog3Data} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Insider - Elfonze Technologies</title>
      <meta key="description" name="description"
      title="Behind the Scenes at Elfonze: Your Gateway to Expert Insights & Analysis"
      content="Uncover industry secrets and expert guidance with our Insider page. Get exclusive behind-the-scenes insights, cutting-edge analysis, and stay ahead of the curve" />
    </>
  )
}

export default Insider;
